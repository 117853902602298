export const questionList = [
  {
    question:
      "Sự nghiệp: anh/chị đã hài lòng với lộ trình sự nghiệp, đã đạt được hết tất cả mục tiêu sự nghiệp chưa?",
    options: [{ value: "Có" }, { value: "Không" }],
    radioValue: "",
  },
  {
    question:
      "Mối quan hệ: anh/chị cảm thấy hòa hợp với tất cả các mối quan hệ xung quanh: đồng nghiệp, bạn bè…?",
    options: [{ value: "Có" }, { value: "Không" }],
    radioValue: "",
  },
  {
    question:
      "Gia đình: Gia đình anh/chị có cảm thấy thật sự hạnh phúc, viên mãn?",
    options: [{ value: "Có" }, { value: "Không" }],
    radioValue: "",
  },
  {
    question:
      "Sức khỏe: anh/chị có luôn cảm thấy khoẻ mạnh, tràn đầy năng lượng hay không?",
    options: [{ value: "Có" }, { value: "Không" }],
    radioValue: "",
  },
  {
    question:
      "Phát triển cá nhân: anh/chị cảm thấy tự tin 100% về bản thân luôn luôn có đầy đủ những kỹ năng ( quản lý, đàm phán, giải quyết vấn đề, thuyết trình…) để phục vụ tốt cho công việc sự nghiệp của mình không?",
    options: [{ value: "Có" }, { value: "Không" }],
    radioValue: "",
  },
  {
    question:
      "Vui chơi giải trí: anh/chị đã khám phá, du lịch những nơi anh chị mong ước chưa?",
    options: [{ value: "Có" }, { value: "Không" }],
    radioValue: "",
  },
  {
    question:
      "Anh/chị đã, đặt chân đến những vùng đất mới, khám phá VN và Thế giới ngoài kia chưa?",
    options: [{ value: "Có" }, { value: "Không" }],
    radioValue: "",
  },
  {
    question:
      "Anh/chị, đã mua những món đồ sa sỉ đắt tiền mà mình hằng ao ước chưa?",
    options: [{ value: "Có" }, { value: "Không" }],
    radioValue: "",
  },
  {
    question:
      "Cống hiến trọng đại: anh/chị đã đóng góp, để lại được gì cho cộng đồng, xã hội, đất nước, thế giới chưa?",
    options: [{ value: "Có" }, { value: "Không" }],
    radioValue: "",
  },
  {
    question:
      "Tâm linh: anh/chị cảm nhận mối liên kết giữa tinh thần và cơ thể trong cuộc sống hàng ngày của bạn như nào? Tâm linh hỗ trợ anh/chị trong lúc khó khăn chiếm bao nhiêu điểm?",
    options: [{ value: "Có" }, { value: "Không" }],
    radioValue: "",
  },
];

export const optionsRadioField = [
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
  {
    value: 3,
    label: 3,
  },
  {
    value: 4,
    label: 4,
  },
  {
    value: 5,
    label: 5,
  },
  {
    value: 6,
    label: 6,
  },
  {
    value: 7,
    label: 7,
  },
  {
    value: 8,
    label: 8,
  },
  {
    value: 9,
    label: 9,
  },
  {
    value: 10,
    label: 10,
  },
];