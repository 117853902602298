import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
export const AdminTemplate = () => {
  const renderBody = () => {
    return (
      <div className="admin-template-body">
        <Header />
        <Outlet />
        <Footer />
      </div>
    );
  };

  return renderBody();
};
