import Client from "app/modules/clientModules";
import Admin from "app/modules/adminModules";

export const URL_PATH_CLIENT = {
  HOME: "/",
  QUESTION: "cau-hoi-tam-ly",
};
export const URL_PATH_ADMIN = {
  HOME: "/admin/",
};

export const clientRoutes = [
  {
    component: Client.ClientModulesHome,
    exact: true,
    path: URL_PATH_CLIENT.HOME,
  },
  {
    component: Client.QuestionForUser,
    exact: true,
    path: URL_PATH_CLIENT.QUESTION,
  }
];
export const adminRoutes = [
  { component: Admin.AdminModulesHome, exact: true, path: URL_PATH_ADMIN.HOME },
];
