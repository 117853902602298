import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import { UTMTracking } from "share/utmTracking";
import CallIcon from "assets/images/icon-call-nh.png";
import LogoZalo from "assets/images/logo-zalo.png";
import LogoMessenger from "assets/images/logo-messenger.png";
import "./styles.scss";
export const ClientTemplate = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.search) {
      UTMTracking(location.search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderBody = () => {
    return (
      <div className="client-template-body">
        <Header />
        <Outlet />
        <Footer />
        <div className="hotline-phone-ring-wrap">
          <div className="hotline-phone-ring">
            <div className="hotline-phone-ring-circle"></div>
            <div className="hotline-phone-ring-circle-fill"></div>
            <div className="hotline-phone-ring-img-circle">
              <a href="tel:02835354859" className="pps-btn-img">
                <img src={CallIcon} alt={CallIcon} width="50" />
              </a>
            </div>
          </div>
          <div className="hotline-bar">
            <a href="tel:02835354859">
              <span className="text-hotline">028 3535 4859</span>
            </a>
          </div>
        </div>
        <div className="logo-zalo-wrap">
          <div className="hotline-phone-ring">
            <div className="hotline-phone-ring-circle"></div>
            <div className="hotline-phone-ring-circle-fill"></div>
            <div className="hotline-phone-ring-img-circle">
              <a
                href="https://zalo.me/0906880917"
                className="pps-btn-img"
                target="_blank"
                rel="noreferrer"
              >
                <img src={LogoZalo} alt={LogoZalo} width="50" />
              </a>
            </div>
          </div>
        </div>
        <div className="logo-messenger-wrap">
          <div className="hotline-phone-ring">
            <div className="hotline-phone-ring-circle"></div>
            <div className="hotline-phone-ring-circle-fill"></div>
            <div className="hotline-phone-ring-img-circle">
              <a
                href="https://m.me/115435774864796"
                className="pps-btn-img"
                target="_blank"
                rel="noreferrer"
              >
                <img src={LogoMessenger} alt={LogoMessenger} width="50" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return renderBody();
};
