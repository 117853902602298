import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "assets/styles/common.scss";
import "assets/fonts/fontawsome.min.css";
// i18n translate language
import { I18nextProvider } from "react-i18next";
import { ParallaxProvider } from "react-scroll-parallax";

import i18n from "assets/i18n";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ParallaxProvider>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </ParallaxProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
